@import '../../../base'
#players-page
  min-width: 1700px

.players-filter.ant-form
  height: 38px
  @include flex
  padding: 0 10px
  background: #fafbfd
  .ant-form-item:last-of-type
    margin: 0 0 0 auto

#players-page .ant-table
  .ant-table-tbody
    .ant-table-row:nth-child(2n)
      background: #fafbfd

  .ant-table-cell
    line-height: 14px!important

  .table-username-td
    @include ellipsis
  .players-table-notes .ant-typography
    margin: 0

  .last-login-ip
    @include flex
    justify-content: space-between

    span
      width: 90%
      min-width: 90%

    svg
      fill: #C11B00


  .info-profit
    font-style: normal
    min-width: 11px
    width: 11px
    height: 11px
    line-height: 11px !important
    font-size: 10px !important
    position: absolute
    top: 0
    right: 0
    @include bold
    @include flex
    justify-content: center
    box-shadow: inset 0 0 0 1px #1B9BD7
    color: #1B9BD7
    &:hover
      cursor: pointer
      background: #1B9BD7
      color: #fff

.players-highlighted-data
  color: #C11B00

.players-total-records
  position: absolute
  bottom: 9px
  left: 12px